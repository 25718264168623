import React, { createContext, useContext, useState, useEffect } from "react";
import { axiosGet } from "../utils/api";
import { isJsonString } from "utils/helpers";
import { useToast } from "@chakra-ui/react";
import { Roles } from "utils/constant";
const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    // case "LOGIN_FAILURE":
    //   return { ...state, isAuthenticated: false };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "SET_USER_INFO":
      return { ...state, userInfo: action.payload?.userInfo };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const userInfoLocalStorage = localStorage.getItem("isUserShow");
  const userInfo = isJsonString(userInfoLocalStorage);
  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated:
      !!localStorage.getItem("xToken") || !!localStorage.getItem("mToken"),
    userInfo: userInfo,
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

async function loginUser(dispatch, token, user, history) {
  if (user?.role === Roles.GUEST) {
    localStorage.setItem("mToken", token);
  } else {
    localStorage.setItem("xToken", token);
  }
  localStorage.setItem("isUserShow", JSON.stringify(user));
  dispatch({ type: "LOGIN_SUCCESS" });
  if (user?.role === Roles.GUEST) {
    history.push("/admin/qr-code");
  } else {
    history.push("/admin");
  }
}

function signOut(dispatch, history) {
  // localStorage.removeItem("id_token");
  localStorage.clear();
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/auth/signin");
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };
