import React, { useState, useEffect } from "react";

import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  useColorModeValue,
  Flex,
  List,
  ListItem,
  Divider,
  Avatar,
  Checkbox,
  FormLabel,
  CheckboxGroup,
  Text,
} from "@chakra-ui/react";
import { API_ROUTES, ROOT_API } from "utils/constant";
import useAxios from "axios-hooks";

const DetailMemberDialog = (props) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const textColor = useColorModeValue("gray.500", "white");
  const {
    isOpen,
    onOpen,
    onClose,
    username
  } = props;

  const [{ data, loading }, refetch] = useAxios(
    {
      url: ROOT_API + API_ROUTES.MEMBER_DETAIL(username),
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen]);

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            Thông tin hội viên: {`${data?.data?.username}`}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Flex mb={2}>
              <Text color={textColor} fontSize="md" fontWeight="bold" mr={2}>
                Username:
              </Text>
              <Text color={textColor} fontSize="md">
                {data?.data?.username}
              </Text>
            </Flex>
            <Flex mb={2}>
              <Text color={textColor} fontSize="md" fontWeight="bold" mr={2}>
                Mã ví:
              </Text>
              <Text color={textColor} fontSize="md">
                {data?.data?.wallet?.code}
              </Text>
            </Flex>
            <Flex mb={2}>
              <Text color={textColor} fontSize="md" fontWeight="bold" mr={2}>
                Mạng ví:
              </Text>
              <Text color={textColor} fontSize="lmdg">
                {data?.data?.wallet?.network}
              </Text>
            </Flex>
            <Flex mb={2}>
              <Text color={textColor} fontSize="md" fontWeight="bold" mr={2}>
                Ngân hàng:
              </Text>
              <Text color={textColor} fontSize="md">
                {data?.data?.banks?.at(0)?.name}
              </Text>
            </Flex>
            <Flex mb={2}>
              <Text color={textColor} fontSize="md" fontWeight="bold" mr={2}>
                Tên tài khoản:
              </Text>
              <Text color={textColor} fontSize="md">
                {data?.data?.banks?.at(0)?.accountName}
              </Text>
            </Flex>
            <Flex mb={2}>
              <Text color={textColor} fontSize="md" fontWeight="bold" mr={2}>
                Số thẻ ngân hàng:
              </Text>
              <Text color={textColor} fontSize="md">
                {data?.data?.banks?.at(0)?.accountNumber}
              </Text>
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose();
              }}
            >
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default DetailMemberDialog;
