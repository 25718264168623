// import
import React, { Component } from "react";
import { FaUsers } from "react-icons/fa";
import { MdOutlineQrCode2, MdHistory } from "react-icons/md";
import Profile from "views/Dashboard/Profile.js";
import SignIn from "views/Pages/SignIn.js";
import User from "views/Dashboard/User/User";

import { StatsIcon, PersonIcon } from "components/Icons/Icons";
import Member from "views/Dashboard/Member/Member";
import HistoryList from "views/Dashboard/History";
import { Roles } from "utils/constant";
import QRCode from "views/Dashboard/QRCode";
import GuestHistory from "views/Dashboard/GuestHistory";
import BalanceFluctuations from "views/Dashboard/BalanceFluctuations";

var dashRoutes = [
  {
    path: "/quan-tri",
    name: "Quản Trị",
    icon: <StatsIcon color="inherit" />,
    component: User,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: "/hoi-vien",
    name: "Hội Viên",
    icon: <MdHistory color="inherit" />,
    component: Member,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: "/giao-dich",
    name: "Giao dịch",
    icon: <StatsIcon color="inherit" />,
    component: HistoryList,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: "/qr-code",
    name: "QR Code",
    icon: <MdOutlineQrCode2 color="inherit" size={16} />,
    component: QRCode,
    layout: "/admin",
    role: [Roles.GUEST],
  },
  {
    path: "/history",
    name: "Lịch sử giao dịch",
    icon: <MdHistory color="inherit" size={16} />,
    component: GuestHistory,
    layout: "/admin",
    role: [Roles.GUEST],
  },
  {
    path: "/balance-fluctuations",
    name: "Biến động số dư",
    icon: <MdHistory color="inherit" size={16} />,
    component: BalanceFluctuations,
    layout: "/admin",
    role: [Roles.GUEST],
  },
  {
    name: "ACCOUNT PAGES",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      {
        path: "/thong-tin-ca-nhan",
        name: "Thông Tin Cá Nhân",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
        role: [Roles.ADMIN, Roles.GUEST],
      },
      {
        path: "/signin",
        name: "Sign In",
        component: SignIn,
        layout: "/auth",
        icon: <PersonIcon color="inherit" />,
        hidden: true,
      },
    ],
  },
];
export default dashRoutes;
