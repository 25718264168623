import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import isEmpty from "lodash/isEmpty";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import {
  ROOT_API,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
  initialFilter,
} from "utils/constant";
import Pagination from "components/Pagination/Pagination";
import { API_ROUTES } from "utils/constant";
import Row from "./components/Row";
import InputSearch from "components/InputSearch/InputSearch";
import DatePicker from "components/DatePicker/DatePicker";
import { formatDate } from "utils/helpers";
import moment from "moment";
import _ from "lodash";

const HistoryList = () => {
  const [filter, setFilter] = useState({
    ...initialFilter,
    // status: TRANSACTION_STATUS.APPROVED,
  });
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [type, setType] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [{ data, loading }, refetch] = useAxios(
    {
      url: ROOT_API + API_ROUTES.HISTORY_TRANSACTION,
      params: filter,
    },
    {
      useCache: false,
    },
    {
      manual: true,
    }
  );

  const onClearSearch = () => {
    setFilter(initialFilter);
    setSearchKeyword("");
  };

  const onChangeSearch = (event) => {
    event.persist();
    setSearchKeyword(event.target.value);
  };

  const onFilter = () => {
    setFilter({
      ...filter,
      searchKeyword,
      startDate: startDate ? moment(startDate).toISOString() : "",
      endDate: endDate ? moment(endDate).endOf("days").toISOString() : "",
      type,
    });
  };

  const onChangeDate = (type) => (date) => {
    if (type === "startDate") {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const transactionTypes = useMemo(() => {
    let types = _.omit(TRANSACTION_TYPE, [
      TRANSACTION_TYPE.TRANSFER_USDT,
      TRANSACTION_TYPE.TRANSFER_VND,
    ]);
    const result = Object.values(types);
    return result;
  }, []);

  return (
    <>
      <Box bg="white" mt={{ base: "120px", md: "75px" }} p="10">
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Lịch sử giao dịch
            </Text>
          </CardHeader>
          <CardBody>
            <Stack>
              <Flex mb="12px">
                <FormControl display="flex" alignItems="center" maxW="400px">
                  <FormLabel m="0" pr="10px" minW="120px">
                    Tìm kiếm:
                  </FormLabel>
                  <InputSearch
                    value={searchKeyword}
                    onChange={onChangeSearch}
                    onClearSearch={onClearSearch}
                  />
                </FormControl>
                <FormControl
                  display="flex"
                  alignItems="center"
                  maxW="300px"
                  mr="12px"
                >
                  <FormLabel m="0" pr="10px" minW="120px">
                    Loại giao dịch:
                  </FormLabel>
                  <Select
                    onChange={(e) => {
                      e.preventDefault();
                      setType(e.target.value);
                    }}
                    value={type}
                  >
                    <option value=""></option>
                    {transactionTypes.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>
              <Flex>
                <FormControl
                  display="flex"
                  alignItems="center"
                  maxW="300px"
                  mr="12px"
                >
                  <FormLabel m="0" pr="10px" minW="120px">
                    Ngày bắt đầu:
                  </FormLabel>
                  <DatePicker
                    selectedDate={startDate}
                    onChange={(date) => onChangeDate("startDate")(date)}
                  />
                </FormControl>
                <FormControl
                  display="flex"
                  alignItems="center"
                  maxW="300px"
                  mr="12px"
                >
                  <FormLabel m="0" pr="10px" minW="120px">
                    Ngày kết thúc:
                  </FormLabel>
                  <DatePicker
                    selectedDate={endDate}
                    minDate={startDate}
                    onChange={(date) => onChangeDate("endDate")(date)}
                  />
                </FormControl>
                <Button
                  variant="primary"
                  maxH="30px"
                  m="10px"
                  onClick={onFilter}
                >
                  Lọc
                </Button>
                <Button
                  variant="primary"
                  maxH="30px"
                  m="10px"
                  onClick={() => {
                    setFilter(initialFilter);
                    setEndDate("");
                    setStartDate("");
                    setSearchKeyword("");
                    setType("");
                  }}
                >
                  Cài lại
                </Button>
              </Flex>
            </Stack>
            <Stack overflow="auto">
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th pl="0px" borderColor={borderColor} color="gray.400">
                      Order No
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Username
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Loại giao dịch
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Ví
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      USDT
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      VND
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Tỷ giá
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Trạng thái giao dịch
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Trạng thái chuyển tiền VND/USDT
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Tạo lúc
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Thao tác
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.data?.map((row, index, arr) => (
                    <Row
                      key={row.id}
                      history={row}
                      isLast={index === arr.length - 1 ? true : false}
                      fetchData={refetch}
                    />
                  ))}
                  {isEmpty(data?.data) && !loading && (
                    <Tr>
                      <Td colSpan="9">
                        <Box textAlign="center" height="200px" pt="24px">
                          No data
                        </Box>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Stack>

            <Flex justifyContent={"flex-end"}>
              {!isEmpty(data?.data) && (
                <Pagination
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              )}
            </Flex>
          </CardBody>
        </Card>
      </Box>
    </>
  );
};

export default HistoryList;
